import React, { useEffect } from "react";
import Soc2 from "../Home/WhyCustomerLoveWokelo";
import TrustedBy from "../Home/TrustedBy";
import Form from "./Form";
import Footer from "../Footer";
import WhyCustomerLovesWokeloCarousel from "../Home/WhyCustomerLoveWokeloCarousel";
import TrustedByCopy from "../Home/TrustedbyCopy";
import HowItWorks from "../Home/HowItWorks";
import TrustedbyPE from "../Home/TrustedbyPE";
import WhyWokeloPE from "../PrivateEquity/WhyWokelo";
import { solutionProps } from "../../types/component-props";

type Props = {};
const logos = ['/ticker/bcapital.svg', '/ticker/insight.svg', '/ticker/draper.svg', '/ticker/776.svg', '/ticker/ey.svg', '/ticker/kpmg.svg', '/ticker/berkshire.svg', '/ticker/guggenheim.svg']


const solutions: solutionProps[] = [
  {
    id: 1,
    title: "Sector research",
    desc: <>Build expertise and knowledge on new markets, trends, regulatory landscape, value chain, opportunities and risks</>,
    open: true,
  },
  {
    id: 2,
    title: "Market activity",
    desc: <>Identify notable moves by target companies and other PE firms, such as M&As, fundraises, and partnerships</>,
    open: false,
  },
  {
    id: 3,
    title: "Opportunity Identification",
    desc: <>Enhance deal sourcing, top-of-the funnel quality, and screening with rich market maps on any industry, segment, or technology area</>,
    open: false,
  },
  {
    id: 4,
    title: "Day-0 fact packs",
    desc: <>Rapid research on the business, market dynamics, competition, management strength, and other primary indicators</>,
    open: false,
  },
  {
    id: 5,
    title: "Due diligence",
    desc: <>Go deeper into company performance and trajectory with an expanded set of topics, alternative data and deal room analysis</>,
    open: false,
  },
  {
    id: 6,
    title: "Synthesis and actionable insights",
    desc: <>Get targeted insights to create investment memos, business cases, executive presentations and more</>,
    open: false,
  },


  {
    id: 7,
    title: "Post-investment monitoring",
    desc: <>Granular insights on portfolio performance, market and competitive moves, and company trajectory and news</>,
    open: false,
  },


  {
    id: 8,
    title: "Company-level double clicks",
    desc: <>Company research and Q&A to drill-down multiple levels to uncover hidden insights and perform bespoke research</>,
    open: false,
  },

  {
    id: 9,
    title: "Periodic and real time alerts",
    desc: <>Hyper-personalized news digests with key takeaways to provide timely, actionable decision inputs</>,
    open: false,
  },
];

const KpmgDasAccess = (props: Props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="flex flex-col relative">
      <div className="md:max-h-[100vh] h-full flex flex-col">
        <Form />
        <TrustedbyPE leftAligned={true} logos={logos} />
      </div>
      <div className="pt-16">
        <WhyWokeloPE solutions={solutions} title="Wokelo for Venture Capital" />
      </div>
      {/* <HowItWorks className="" bookDemoLabel="Request Access" showCalendly={false} routeAfterSubmit={false}/> */}
      <HowItWorks className="" bookDemoLabel="Book Demo" />
      <WhyCustomerLovesWokeloCarousel
        title="Why our customers love Wokelo"
        titleClassName="text-[24px] leading-[28px] md:text-[36px] md:leading-[43px]"
        subTitleClassName="md:w-full"
        subtitle={<>Don't just take our word for it. Hear from those who have witnessed <br /> the magic of Wokelo.AI firsthand.</>}
        customTestimonials={[
          {
            id: 1,
            name: "Steven Imes IV",
            role: "VC Analyst, Sage Collective",
            description: `When it comes to investment due diligence, Wokelo has been a game-changer for me. I've used many generative AI tools over the past year, but Wokelo's industry & company research tools have provided the most accurate and comprehensive reports. As an analyst combing through dozens of documents daily, the Data Room Synthesis helps me extract insights from huge data rooms and draft investment memos. Wokelo helps me get smart fast, so I can focus my research in the right places.`,
            img: "/client/logos/sage.png",
            profileImg: "/client/profile/steven.png",
            pad: 5,
          },
          {
            id: 4,
            name: "Yohei Nakajima",
            role: "General Partner, Untapped Capital",
            description: `Over the past 2 years we have built several in-house Gen-AI solutions for automating our investment process. As a builder, I also devoted considerable effort to building an AI-powered diligence tool. We recently came across Wokelo.ai, the ability to instantly synthesize insights from diverse sources, helps us make faster and smarter investment decisions. Excited to see what we can do when we incorporate this into our BabyAGI agent. For a streamlined VC firm like ours, Wokelo has enhanced our due diligence process tenfold`,
            img: "/client/logos/untapped.svg",
            profileImg: "/client/profile/Yohei.jpg",
            imageSize: "smaller",
            pad: 0,
          },
          {
            id: 5,
            name: "Tyler Dean",
            role: "Co-founder, Confluence.VC",
            description: `Partnering with Wokelo has been nothing short of transformative for us at Confluence.VC. We've been able to provide the VC community with unparalleled insights across diverse topics, fundamentally elevating their productivity. It's not just about working smarter, it's about reshaping the future of venture capital.`,
            img: "/client/logos/confluence.png",
            profileImg: "/client/profile/tyler.png",
            imageSize: "smaller",
            pad: 5,
          },
        ]}
      />
      <Footer theme="dark"></Footer>
    </div>
  );
};

export default KpmgDasAccess;
