import React, { useEffect } from "react";
import Soc2 from "../Home/WhyCustomerLoveWokelo";
import TrustedBy from "../Home/TrustedBy";
import Form from "./Form";
import Footer from "../Footer";
import WhyCustomerLovesWokeloCarousel from "../Home/WhyCustomerLoveWokeloCarousel";
import TrustedByCopy from "../Home/TrustedbyCopy";
import HowItWorks from "../Home/HowItWorks";
import WhyWokeloPE from "./WhyWokelo";
import TrustedbyPE from "../Home/TrustedbyPE";
import { solutionProps } from "../../types/component-props";

type Props = {};
const logos = ['/Berkshire_Partners.svg', '/ey.svg', '/Insight_Partners.svg', '/KPMG.svg', '/premji.svg']

const solutions: solutionProps[] = [
  {
      id: 1,
      title: "Sector research",
      desc: <>Build expertise and knowledge on new markets, trends, regulatory landscape, value chain, opportunities and risks</>,
      open: true,
  },
  {
      id: 2,
      title: "Market activity",
      desc: <>Identify notable moves by target companies and other PE firms, such as M&As, fundraises, and partnerships</>,
      open: false,
  },
  {
      id: 3,
      title: "Deal sourcing",
      desc: <>Build a detailed view of the player landscape by segment, category, and company type to discover based on bespoke rules</>,
      open: false,
  },
  {
      id: 4,
      title: "Day-0 fact packs",
      desc: <>Rapid research on the business, market dynamics, competition, management strength, and other primary indicators</>,
      open: false,
  },
  {
      id: 5,
      title: "Due diligence",
      desc: <>Go deeper into company performance and trajectory with an expanded set of topics, alternative data and deal room analysis</>,
      open: false,
  },
  {
      id: 6,
      title: "Synthesis and Actionable Insights",
      desc: <>Get targeted insights to create investment memos, business cases, executive presentations and more</>,
      open: false,
  },


  {
      id: 7,
      title: "Post-investment monitoring",
      desc: <>Granular insights on company news, and market and competitor moves, to ensure PortCo success</>,
      open: false,
  },


  {
      id: 8,
      title: "Company-level double clicks",
      desc: <>Company research and Q&A to drill-down multiple levels to uncover hidden insights and perform bespoke research</>,
      open: false,
  },

  {
      id: 9,
      title: "Periodic and real time alerts",
      desc: <>Hyper-personalized news digests with key takeaways to provide timely, actionable decision inputs</>,
      open: false,
  },
];

const PrivateEquityAccess = (props: Props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="flex flex-col relative">
      <Form />
      <TrustedbyPE leftAligned={true} logos={logos} />
      <WhyWokeloPE solutions={solutions}/>
      <HowItWorks className="" />
      <WhyCustomerLovesWokeloCarousel
        title="Why our customers love Wokelo"
        titleClassName="text-[24px] leading-[28px] md:text-[36px] md:leading-[43px]"
        subTitleClassName="md:w-full"
        subtitle={<>Don't just take our word for it. Hear from those who have witnessed <br/> the magic of Wokelo.AI firsthand.</>}
      />
      <Footer theme="dark"></Footer>
    </div>
  );
};

export default PrivateEquityAccess;
