import VentureCapitalAccess from "../core/components/VentureCapital/VentureCapitalAccess";
import TradedVCAccess from "../core/components/tradedvcAccess/TradedVCAccess";

type Props = {};

const VentureCapital = (props: Props) => {
  return <VentureCapitalAccess></VentureCapitalAccess>;
};

export default VentureCapital;
