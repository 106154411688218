import { animate, AnimationPlaybackControls, motion, useAnimationControls, useMotionValue } from "framer-motion"
import { useEffect, useRef, useState } from "react";
import useMeasure from "../../utils/useMeasure";
const logos = [
    {
        src: "/berkshire.svg",
        name: "Berkshire"
    }, {
        src: "/draper.svg",
        name: "Draper"
    },
    {
        src: "/ey.svg",
        name: "EY"
    }, {
        src: "/google.svg",
        name: "Google"
    }, {
        src: "/guggenheim.svg",
        name: "Guggenheim"
    },
    {
        src: "/insight.svg",
        name: "Insight"
    }, {
        src: "/kpmg.svg",
        name: "KPMG"
    }, {
        src: "/premji.svg",
        name: "Premji"
    }, {
        src: "/siemens.svg",
        name: "Siemens"
    }, {
        src: "/tata.svg",
        name: "Tata"
    }
]

export default function TrustedByV2() {
    const [ref, { width, height }] = useMeasure()
    const [duration] = useState(100)
    const xTranslation = useMotionValue(0)
    const [mustFinish, setMustFinish] = useState(false)
    const [rerender, setRerender] = useState(false)

    useEffect(() => {
        let controls: AnimationPlaybackControls
        const finalPos = -width / 2
        if (mustFinish) {
            controls = animate(xTranslation, [xTranslation.get(), finalPos], {
                duration: duration * (1 - xTranslation.get() / finalPos),
                onComplete: () => {
                    setMustFinish(false)
                    setRerender(!rerender)
                },
                ease: "linear",
            })
        } else {
            controls = animate(xTranslation, [0, finalPos], {
                repeat: Infinity,
                repeatType: "loop",
                duration,
                ease: "linear",
                repeatDelay: 0,
            })
        }
        return () => controls.stop()
    }, [width, xTranslation, duration, rerender])

    return (
        <section className="pt-5 pb-20 space-y-10 flex flex-col items-center bg-white">
            <h2 className="text-lg text-[#525252]">Trusted by industry leaders</h2>
            <div className="my-12 relative overflow-hidden w-full" style={{ height }}>
                <div className="absolute left-0 w-full z-10" style={{ height, background: "linear-gradient(90deg, rgba(256,256,256,1) 0%, rgba(255,255,255,0) 15%, rgba(255,255,255,0) 85%, rgba(256,256,256,1) 100%)" }} />

                <motion.div
                    ref={ref}
                    className="absolute left-0 flex items-center"
                    style={{
                        x: xTranslation,

                    }}
                >

                    {[...logos, ...logos, ...logos, ...logos].map((logo, idx) => (
                        <div key={idx} className="relative px-5 lg:px-10 xl:px-16">
                            <div className="w-max">
                                <img
                                    src={`/ticker${logo.src}`}
                                    alt={logo.name}
                                    style={{
                                        imageRendering: "crisp-edges",
                                        transform: "translateZ(0)"
                                    }}
                                />
                            </div>
                        </div>
                    ))}
                </motion.div>
            </div>
        </section>
    );
}