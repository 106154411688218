import { useEffect, useRef, useState } from "react";

import FeatureSection from "./FeatureSection";
import HowItWorks from "./HowItWorks";
import Landing from "./Landing";
import Solutions from "./Solutions";
import Soc2 from "./Soc2";
import WhyCustomerLoveWokelo from "./WhyCustomerLoveWokelo";
import AmplifyHumanProductivity from "./AmplifyHumanProductivity";
import TrustedBy from "./TrustedBy";
import Landing_2 from "./Landing_2";
import Navbar from "../Navbar";
import Footer from "../Footer";
import Landing3 from "./Landing3";
import Landing4 from "./Landing4";
import ReactPlayer from "react-player";
import Vimeo from "@u-wave/react-vimeo";
import { useMediaQuery, useTheme } from "@mui/material";
import WhyCustomerLovesWokeloCarousel from "./WhyCustomerLoveWokeloCarousel";
import TrustedByV2 from "./TrustedByV2";
import TrustedByLandingPage from "./TrustedByLandingPage";

export default function Home() {
  const [landing, setLanding] = useState(false);

  const [scrollY, setScrollY] = useState(0);
  const [scrollHeight, setScrolHeight] = useState(1);
  const inputRef = useRef(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  function logit() {
    setScrollY(window.scrollY);
  }

  useEffect(() => {
    function watchScroll() {
      window.addEventListener("scroll", logit);
    }
    watchScroll();
    return () => {
      window.removeEventListener("scroll", logit);
    };
  });

  const isElementXPercentInViewport = function () {
    const viewportHeight = window.innerHeight;
    const scrollPercentage = (window.scrollY / viewportHeight) * 100;

    if (scrollPercentage >= 55) {
      return 4;
    } else if (scrollPercentage >= 40) {
      return 3;
    } else if (scrollPercentage >= 20) {
      return 2;
    } else {
      return 1;
    }
  };

  useEffect(() => {
    setScrolHeight(isElementXPercentInViewport());

    if (
      scrollY > 20 &&
      (document.activeElement !== inputRef.current || !isMobile)
    ) {
      if (!landing) {
        setLanding(true);
      }
    } else if (scrollY == 0) {
      setLanding(false);
    } else {
      return;
    }
  }, [scrollY]);

  return (
    <>
      <div className={`flex flex-col relative`}>
        <Landing3 setLanding={setLanding} landing={landing} ref={inputRef} />

        {/* <Landing4 setLanding={setLanding} landing={landing} /> */}

        <TrustedByLandingPage scrollHeight={scrollHeight} />

        <FeatureSection />

        <HowItWorks />

        <Solutions />

        <Soc2 />

        <WhyCustomerLovesWokeloCarousel></WhyCustomerLovesWokeloCarousel>

        <AmplifyHumanProductivity></AmplifyHumanProductivity>
      </div>
      <Footer />
    </>
  );
}
